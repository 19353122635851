import React, { useState, useEffect } from 'react';
import Parse from 'parse'
import { Link, Route } from "react-router-dom";
import { makeStyles, createMuiTheme, useTheme, ThemeProvider } from '@material-ui/core/styles';
import clsx from 'clsx';
import { Button } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Collapse from '@material-ui/core/Collapse';
import TocOutlinedIcon from '@material-ui/icons/TocOutlined';
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import Avatar from '@material-ui/core/Avatar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import { red } from '@material-ui/core/colors';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import FavoriteIcon from '@material-ui/icons/Language';
import ShareIcon from '@material-ui/icons/MailOutline';
import Chip from '@material-ui/core/Chip';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemText from '@material-ui/core/ListItemText';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import CircularProgress from '@material-ui/core/CircularProgress';

import InfiniteCarousel from 'react-leaf-carousel';

import PeopleAltIcon from '@material-ui/icons/PeopleAlt';
import EuroIcon from '@material-ui/icons/Euro';
import BuildIcon from '@material-ui/icons/Build';
import CastForEducationIcon from '@material-ui/icons/CastForEducation';

import logo from '../src/logo.png';
import logohead from '../src/img.jpg';
import digital from '../src/digital.jpg';

Parse.serverURL = "https://homeuser.opendash.de/sswf";
Parse.initialize("sustainswf");

const useStyles = makeStyles((theme) => ({
  root: {
    width: 345,
    minWidth: 345,
    minHeight: 650,
    float:"left",
    margin:"10px",
    position:"relative",
  },
  media: {
    height: 0,
    paddingTop: '56.25%', // 16:9
    backgroundRepeat: "no-repeat",
    backgroundSize: "90%"
  },
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
  avatar: {
    backgroundColor: red[500],
  },
  appBar: {
    top: 'auto',
    bottom: 0,
  },
  large: {
    width: theme.spacing(7),
    height: theme.spacing(7),
  },
}));

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#2979ff',
    },
    secondary: {
      main: '#357a38',
    },
  },
});




function App() {

  const classes = useStyles();
  const color = useTheme();

  const [settings, setSettings] = useState({
    expanded: false,
    loading: true,
    loadingPuffer: true,
    tagElement: [],
    contentElement: [],
    parseContent: [],
    selectedTag: "Alle"
  })

  var content = settings.parseContent;
  var tagListElement = [];
  var tagList = [];


  //Get Initial Data from Parse Server and set State
  const Content = Parse.Object.extend("Content");
  const query = new Parse.Query(Content);
  useEffect(() => {
    const results = query.find();
    results.then(content=>{
      setSettings((prevState) => ({
        ...prevState,
        parseContent: content,
      }));
    });
  }, [settings.loading]); // eslint-disable-line react-hooks/exhaustive-deps

  //If Parse Content changing (First Init)
  useEffect(() => {
    console.log("Parse Content changed...");

    setSettings((prevState) => ({
      ...prevState,
      selectedTag: "Init"+new Date(),
      contentElement: getElements(prevState.parseContent, prevState.selectedTag, prevState.expanded),
    }));

  }, [settings.parseContent]);

  //Selected Tag changing
  useEffect(() => {
    console.log("Selected Tag changed... ", settings.selectedTag);

    //Make TagList
    content.forEach(element => {
      element.get("tags").forEach(tagElement => {
        tagList.push(tagElement);  
      })
    });

    var tagPuffer = settings.selectedTag;
    if(settings.selectedTag.startsWith("Init")) {
      tagPuffer = "Alle";
    }

    //Create Tag Elements
    tagList = Array.from(new Set(tagList));
    
    if("Alle" === settings.selectedTag) {
      tagListElement.push(<ThemeProvider theme={theme}><Chip style={{margin:"3px"}} label="Alle" color="primary" onClick={()=>handleClick("Alle")}/></ThemeProvider>)
    } else {
      tagListElement.push(<Chip style={{margin:"3px"}} label="Alle" onClick={()=>handleClick("Alle")}/>)
    }
    tagList.forEach(element => {
      if(element === settings.selectedTag) {
        tagListElement.push(<Chip style={{margin:"3px"}} label={element} color="primary" onClick={()=>handleClick(element)}/>)
      } else {
        tagListElement.push(<Chip style={{margin:"3px"}} label={element} onClick={()=>handleClick(element)}/>)
      }
    });

    setSettings((prevState) => ({
      ...prevState,
      selectedTag: tagPuffer,
      tagElement: tagListElement,
      contentElement: getElements(prevState.parseContent, tagPuffer, prevState.expanded),
    }));
  }, [settings.selectedTag]);

  //Content Element changeing
  useEffect(() => {
    console.log("Content Element changed...");

    setSettings((prevState) => ({
      ...prevState,
      loadingPuffer: false,
    }));

  }, [settings.contentElement]);


  //Loading Fix
  useEffect(() => {
    const interval = setInterval(() => {
      if(!settings.loadingPuffer) {
        clearInterval(interval);
        setSettings((prevState) => ({
          ...prevState,
          timerLoad: null,
          loading: false,
        }));
      }



    }, 1000);
    return () => clearInterval(interval);
  }, [settings.loadingPuffer]);

  


  //Expand Categories Click
  const handleExpandClick = () => {
    setSettings((prevState) => ({
      ...prevState,
      contentElement: getElements(content, prevState.selectedTag, !prevState.expanded),
      expanded: !prevState.expanded,
    }));
  };

  //Filter Categories Click
  const handleClick = (tag) => {
    setSettings((prevState) => ({
      ...prevState,
      selectedTag: tag,
    }));
  };  

  const getLogos = () => {


    var elementLogoSlider = [];
    
    content.forEach(element => {
      elementLogoSlider.push(<div style={{height:"150px"}}><img  style={{width:"100%",height:"100%", objectFit: "contain"}} alt='' src={element.get("Logo")}/></div>)});

    return elementLogoSlider;

  }

  const footer = () => {
    return (<div><div style={{backgroundColor: "rgb(58, 131, 255)", marginTop:"10px"}}>
    <div style={{width: "100%",paddingTop: "15px",paddingBottom:"15px", marginRight: "auto",marginLeft: "auto", color:"white"}}>
    <div style={{textAlign:"center",width:"100%", fontSize:"20px"}}>
       <b> Möchten Sie auch auf die Liste und Teil des Netzwerks sein? &nbsp;
        <a style={{color:"white"}} href={"mailto:nico.castelli@fit.fraunhofer.de?subject=Eintrag Nachhaltigkeit Südwestfalen&body=Wir benötigen: Namen der Organisation, Logo, Url, Kurzbeschreibung (200 Zeichen), 4 Punkte für Angebote, Name der Kontaktperson, Mail der Kontaktperson, Foto Kontaktperson, Stichpunkte zu den Angeboten (bspw. Veranstaltungen, Förderungsberatung, Software, Beratung...)"}>
          Hier klicken
        </a></b>
      </div>
    </div>
  </div>
    
      <Grid style={{width: "100%",paddingRight: "15px",paddingLeft: "15px",marginRight: "auto",marginLeft: "auto", backgroundColor:"#3d3d40", color:"white", paddingTop:"20px", paddingBottom:"20px"}} container  direction="row" justify="flex-end" alignItems="baseline">
        <Grid item xs>
        </Grid>
        <Grid item xs>
        </Grid>
        <Grid item xs>
         
        </Grid>
        <Grid item xs>
          <div>
            <List dense={true}>

                <ListItem>
                  <ListItemAvatar>
                    <TocOutlinedIcon />
                  </ListItemAvatar>
                  <ListItemText
                    primary="Linkliste"
                  />
                </ListItem>
                <ListItem>
                  <ListItemAvatar>
                      <InfoOutlinedIcon />
                  </ListItemAvatar>
                  <ListItemText
                    primary="Impressum"
                  />
                </ListItem>
                <ListItem>
                  <ListItemAvatar>
                    <MailOutlineIcon />
                  </ListItemAvatar>
                  <ListItemText
                    primary="Datenschutz"
                  />
                </ListItem>
            </List>
          </div>
        </Grid>
      </Grid></div>);
  }

  const header = () => {
    return (<div style={{position:"fixed",top:"0px",width:"100%",maxWidth:"2000px",height:"55px",color:"white",zIndex:"2"}}>
    <div style={{backgroundColor: "rgb(58, 131, 255)",height:"55px",float:"left",width:"1%"}}>
      
    </div>
    <div style={{backgroundColor: "#3d3d40",height:"55px",float:"left",width:"99%"}}>
      <img style={{margin:"auto", paddingTop:"10px", paddingLeft:"20px", height:"60%"}} src={logo}></img> <b class="hidden-mobile" style={{fontSize:"24px", paddingLeft:"10px"}}> sustain.Südwestfalen</b>
      <div style={{float:"right", paddingTop:"25px", paddingRight:"20px"}}><b style={{fontSize:"14px", }}><Link to="/">Startseite</Link></b><b> &nbsp;&nbsp;|&nbsp;&nbsp; </b><b style={{fontSize:"14px"}}><Link to="/list">Partner suchen und finden</Link></b></div>
    </div>
  </div>)
  }

  const frontpage = () => {
    return (
    <div>

      
      <div class="container" style={{height:"500px",width:"100%"}}>
        <img style={{width:"100%",height:"500px", objectFit: "cover", filter:"grayscale(30%)"}} src={logohead}></img>
        <div class="centered" style={{whiteSpace: "nowrap"}}><b>Gemeinsam | Regional | Nachhaltig</b></div>
      </div>




      <div class="doubleheight" style={{width:"100%"}}>
        <Grid style={{width: "100%", height:"100%", paddingRight: "15px",paddingLeft: "15px", paddingTop:"20px", paddingBottom:"20px"}} container  direction="row" justify="flex-end" alignItems="baseline">
          <Grid style={{height:"300px"}} item xs={12} md={4}>
            <div class="container" style={{height:"100%",width:"100%"}}>
            <div class="centered borderright" style={{color:"black", fontSize:"32px"}}>Was ist sustain Südwestfalen?</div>
            </div>
          </Grid>
          <Grid style={{height:"300px"}} item xs={12} md={8}>
            <div class="container"  style={{height:"100%",width:"100%",color:"black"}}>
              <div class="centered" style={{fontSize:"22px", width:"90%"}}><i>sustain.Südwestfalen</i> ist ein informelles und regionales Netzwerk, welches die Themen <b>Nachhaltigkeit</b>, <b>Energie- und Ressourceneffizienz</b> sowie <b>Digitalsierung</b> adressiert. Es dient der <b>Vernetzung</b> und der Schaffung einer <b>Transparenz</b> für und über Ansprechpartner und Akteure in der <b>Region</b>, die bei den Themenstellung unterstützen.</div>
            </div>
          </Grid>
        </Grid>
      </div>



      <div style={{width:"100%"}}>
        <h1 style={{width:"100%",textAlign:"center", paddingBottom:"20px"}}>Wir addressieren vier Themen:</h1>
        <Grid style={{width: "100%", height:"100%", paddingRight: "0px",paddingLeft: "0px", paddingTop:"20px", paddingBottom:"20px", display:"flex", flexGrow: "1"}} container  direction="row"  spacing={0}>
          <Grid style={{height:"100%", display: "flex", flexFlow: "column"}} item xs={12} md={3}>
            <div class="container gridboxes"  style={{width:"100%",color:"white", backgroundColor:"#748BA7"}}>
              <div style={{fontSize:"18px", paddingTop:"15px"}}>
                <PeopleAltIcon fontSize="large"/><h4 style={{fontSize:"25px"}}>Beratung</h4>
                <p style={{hyphens: "auto", textAlign: "justify", padding:"12px", paddingTop:"2px"}}>Wer sind die Ansprechpartner in der Region die unterstützen effizienzter und nachhaltiger zu werden. Wir zeigen auf, welche Partner für diese Themenstellungen Hilfe leisten. Dabei finden Sie sowohl Dienstleister, als auch Vereine und Behörden.</p>
              </div>
            </div>
          </Grid>
        <Grid style={{height:"100%", display: "flex", flexFlow: "column"}} item xs={12} md={3}>
            <div class="container gridboxes" style={{width:"100%",color:"white", backgroundColor:"#143153"}}>
              <div style={{fontSize:"18px", paddingTop:"15px"}}>
                <EuroIcon fontSize="large"/><h4 style={{fontSize:"25px"}}>Förderung</h4>
                <p style={{hyphens: "auto", textAlign: "justify", padding:"12px", paddingTop:"2px"}}>Was kann gefördert werden, wo finde ich Informationen und wer unterstützt mich bei der Einschätzung und Antragsstellung. Im Netzwerk finden Sie die passenden Ansprechpartner, die Sie zum Thema Förderung beraten und unterstützen.</p>
              </div>
            </div>
          </Grid>
          <Grid style={{height:"100%", display: "flex", flexFlow: "column"}} item xs={12} md={3}>
            <div class="container gridboxes" style={{width:"100%",color:"white", backgroundColor:"#748BA7"}}>
              <div style={{fontSize:"18px", paddingTop:"15px"}}>
                <BuildIcon fontSize="large"/><h4 style={{fontSize:"25px"}}>Umsetzung</h4>
                <p style={{hyphens: "auto", textAlign: "justify", padding:"12px", paddingTop:"2px"}}>Welche Partner in der Region helfen bei der Umsetzung von Effizienzmaßnahmen, sei es bei der Installation von Sensorik, der Auswertung von digitalen Daten oder bei der Umsetzung weitere Effizienzmaßnahmen, die Ihnen helfen nachhaltiger zu werden.</p>
              </div>
            </div>
          </Grid>
          <Grid style={{height:"100%", display: "flex", flexFlow: "column"}} item xs={12} md={3}>
            <div class="container gridboxes" style={{width:"100%",color:"white", backgroundColor:"#051C38"}}>
              <div style={{fontSize:"18px", paddingTop:"15px"}}>
                <CastForEducationIcon fontSize="large"/><h4 style={{fontSize:"25px"}}>Forschung</h4>
                <p style={{hyphens: "auto", textAlign: "justify", padding:"12px", paddingTop:"2px"}}>Im Netzwerk befinden sich zusätzlich Partner aus Wissenschaft und Forschung, damit die Themen Energieeffizienz und Ressourceneffizienz sowie Nachhaltigkeit auch zukunftssicher angegangen werden können. Nachhaltigkeit nachhaltig gestalten.</p>
              </div>
            </div>
          </Grid>
        </Grid>
      </div>

      <div style={{width:"100%", paddingBottom:"30px"}}>
      <h1 style={{width:"100%",textAlign:"center", paddingBottom:"20px", paddingTop:"20px"}}>Ziele von sustain.Südwestfalen:</h1>
        
        
        
      <Grid style={{width: "100%", height:"100%", padding: "15px", display:"flex", flexGrow: "1"}} container  direction="row"  spacing={1}>
        <Grid style={{height:"100%",fontSize:"18px"}} item xs={12} md={7}>
          <div class="goalbox">
          <p style={{textAlign:"justify"}} >Ziel von <i>sustain.Südwestfalen</i> ist die Vernetzung regionaler Akteure und Stakeholder, die sich mit den Themen Nachhaltigkeit, Energie- und Ressourceneffizienz sowie Digitalsierung beschäftigen. <i>sustain.Südwestfalen</i> ist eine Netzwerk- und Transparenz Plattform in der Akteure sich beteiligen können und Unternehmen geeignete Partner und Ansprechpartner identifizieren können um nachhaltiger zu agieren. In regelmäßgen Abständen finden Vernetzugnsveranstaltungen statt, wie beispielsweise der Runde Tisch des Mittelstand 4.0 Kompetenzzentrums Siegen, wo neben Impulsvorträgen auch diskutiert und sich ausgetauscht werden kann. Die Teilnahme an den Veranstaltungen und dem Netzwerk ist kostenlos. </p>
          Zusammengefasst sind die Ziele von <i>sustain.Südwestfalen</i>:
          <ul class="a">
            <li class="a">Übersicht von Akteuren im Themenbereich Nachhaltigkeit</li>
            <li class="a">Transparenz über Angebote und Möglichkeiten</li>
            <li>Vernetzung und Synergien zwischen Teilnehmern</li>
            <li>Übersicht über aktuelle Themen und Veranstaltung</li>
            <li>Einfaches finden von Partnern für Projekte</li>
            <li>Informationen über Wissenschaft und Forschung zu diesem Themenbereich</li>
            <li>Regionales Netzwerk mit kurzen Wegen</li>
          </ul>
          </div>
        </Grid>
        <Grid style={{height:"100%",padding:"20px"}} item xs={12} md={5}>
        <img style={{height:"500px", width:"75%", objectFit: "cover"}} src={digital}></img>
        </Grid>
      </Grid>






      </div>

      





  


      <div style={{width:"100%", paddingBottom:"30px",backgroundColor: "#748BA7"}}>
      <h1 style={{width:"100%",textAlign:"center", paddingBottom:"20px", paddingTop:"50px", color:"white"}}>Jetzt Partner suchen und finden!</h1>
      <div class="container" style={{paddingBottom:"90px"}}>
        <div class="centered"> 
        <Button variant="contained" color="primary" style={{backgroundColor:"#456", height: "100px", width: "500px"}}><Link to="/list">Hier klicken!</Link></Button>
        </div>
      </div>
      
        
      </div>









    








      <div style={{width:"100%", paddingBottom:"30px"}}>
      <h1 style={{width:"100%",textAlign:"center", paddingBottom:"20px", paddingTop:"20px"}}>Aktuelle Partnerliste:</h1>
      <InfiniteCarousel
    breakpoints={[
      {
        breakpoint: 500,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
    ]}
    animationDuration={1000}
    autoCycle={true}
    dots={false}
    showSides={false}
    sidesOpacity={.5}
    arrows={false}
    sideSize={.1}
    slidesToScroll={5}
    slidesToShow={5}
    scrollOnDevice={true}
  >
            {getLogos()}
            </InfiniteCarousel>

</div>
















      <div style={{width:"100%", paddingBottom:"20px"}}>
        <h1 style={{width:"100%",textAlign:"center", paddingTop:"20px"}}>Wer steht hinter dem Netzwerk:</h1>
        <div style={{margin:"auto",padding:"5px",paddingTop:"5px",fontSize:"25px"}}>
          <div style={{width:"100%", textAlign:"center"}}>
            Das Netzwerk ist ein Gemeinschaftsprojekt regionaler Akteure aus Südwestfalen. Betreuung und Initiierung finden durch die Mitarbeiter der Universität Siegen sowie des Fraunhofer Instituts für angewandte Informationstechnologie statt.

            <Grid style={{width: "100%", height:"100%"}} container  direction="row">
              <Grid style={{height:"100%", display: "flex", flexFlow: "column"}} item xs={12} md={6}>
                <div  style={{padding:"20px", height:"100%", width:"100%", textAlign:"center"}}>
                  <div style={{margin:"auto", fontSize:"18px", paddingTop:"80px"}}>
                    <Avatar alt="Nico Castelli" src="https://www.wineme.uni-siegen.de/wp-content/uploads/2017/11/Nico_Castelli.jpg" className={classes.large} style={{position: "relative", top: "50%", left: "50%", transform: "translate(-50%, -50%)", height:"150px", width:"150px"}}/>
                    <p style={{padding:"0px",margin:"0px"}}>Dr. Nico Castelli</p>
                    <p style={{padding:"0px",margin:"0px"}}><a href="mailto:nico.castelli@fit.fraunhofer.de">Mail senden</a></p>
                  </div>
                </div>
              </Grid>
              <Grid style={{height:"100%", display: "flex", flexFlow: "column"}} item xs={12} md={6}>
                <div  style={{padding:"20px", height:"100%", width:"100%", textAlign:"center"}}>
                  <div style={{margin:"auto", fontSize:"18px", paddingTop:"80px"}}>
                    <Avatar alt="Max Krüger" src="https://kompetenzzentrum-siegen.digital/wp-content/uploads/2020/05/Krüger.jpg" className={classes.large} style={{position: "relative", top: "50%", left: "50%", transform: "translate(-50%, -50%)", height:"150px", width:"150px"}}/>
                    <p style={{padding:"0px",margin:"0px"}}>Max Krüger</p>
                    <p style={{padding:"0px",margin:"0px"}}><a href="mailto:Maximilian.Krueger@uni-siegen.de">Mail senden</a></p>
                  </div>
                </div>
              </Grid>
            </Grid>

          </div>
        </div>
      </div>








      <div style={{width:"100%",backgroundColor: "#3d3d40"}}>
        

      <Grid style={{width: "100%", height:"100%"}} container  direction="row">
          <Grid style={{height:"100%", display: "flex", flexFlow: "column", color:"white"}} item xs={12} md={4}>
            <div  style={{padding:"20px", height:"100%", width:"100%", textAlign:"center"}}>
              <div >
                  <p style={{fontSize:"19px"}}>Partner</p>
                  <h1><b style={{fontSize:"100px"}}>5</b></h1>
              </div>
            </div>
          </Grid>
          <Grid style={{height:"100%", display: "flex", flexFlow: "column", color:"white"}} item xs={12} md={4}>
            <div  style={{padding:"20px", height:"100%", width:"100%", textAlign:"center"}}>
              <div >
                  <p style={{fontSize:"19px"}}>Veranstaltungen</p>
                  <h1><b style={{fontSize:"100px"}}>3</b></h1>
              </div>
            </div>
          </Grid>
          <Grid style={{height:"100%", display: "flex", flexFlow: "column", color:"white"}} item xs={12} md={4}>
            <div  style={{padding:"20px", height:"100%", width:"100%", textAlign:"center"}}>
              <div >
                  <p style={{fontSize:"19px"}}>Vernetzungen</p>
                  <h1><b style={{fontSize:"100px"}}>8</b></h1>
              </div>
            </div>
          </Grid>
        </Grid>



      </div>







      <div style={{width:"100%", paddingBottom:"50px",paddingTop:"40px"}}>
        <h1 style={{width:"100%",textAlign:"center", paddingTop:"20px"}}>Wer kann mitmachen:</h1>
        <div style={{margin:"auto",padding:"50px",paddingTop:"10px",fontSize:"25px"}}>
          <div style={{width:"100%", textAlign:"center"}}>
          <i>sustain.Südwestfalen</i> ist ein informelles Netzwerk, daher hat jeder der interessiert ist, die Möglichkeit sich in die Linkliste eintragen zu lassen. Wir versuchen Akteure aus verschiedenen Bereichen (Politik, Wissenschaft und Wirtschaft) zu integrieren, um eine komplette Übersicht regionaler Partner bieten zu könnne. Wenn Sie interesse haben, klicken Sie gerne auf den Link unten, um sich in die Liste eintragen zu lassen.
         
         
          </div>
        </div>
        
      </div>





    </div>)
  }

  const list = () => {
    return (<div><Grid container align="center" justify="center" style={{paddingTop:"30px"}}>
    {settings.tagElement}
  </Grid>
  <Grid container align="center" justify="center" style={{paddingTop:"30px"}}>
    {settings.contentElement}
  </Grid></div>)
  }
  
  //Main
  return (
    <div style={{width:"100%"}}><div style={{maxWidth:"2000px", margin:"auto",backgroundColor:"white"}}>
      {settings.loading &&
        <div style={{position: "absolute",top: "50%",left: "50%", transform: "translate(-50%, -50%)", zIndex:"99999999"}}>
          <CircularProgress style={{height:"150px",width:"150px"}}/>
        </div>
      }
      {!settings.loading &&
        
        <div style={{marginTop:"50px"}}>
        {header()}
        <Route exact path="/">
          {frontpage()}
        </Route>
        <Route path="/list">
        <div class="container" style={{height:"500px",width:"100%"}}>
        <img style={{width:"100%",height:"500px", objectFit: "cover", filter:"grayscale(30%)"}} src={logohead}></img>
        <div class="centered" style={{whiteSpace: "nowrap"}}><b>Gemeinsam | Regional | Nachhaltig</b></div>
      </div>




      <div class="doubleheight" style={{width:"100%"}}>
        <Grid style={{width: "100%", height:"100%", paddingRight: "15px",paddingLeft: "15px", paddingTop:"20px", paddingBottom:"20px"}} container  direction="row" justify="flex-end" alignItems="baseline">
          <Grid style={{height:"300px"}} item xs={12} md={4}>
            <div class="container" style={{height:"100%",width:"100%"}}>
            <div class="centered borderright" style={{color:"black", fontSize:"32px", paddingRight:"20px"}}>SUCHEN und FINDEN!</div>
            </div>
          </Grid>
          <Grid style={{height:"300px"}} item xs={12} md={8}>
            <div class="container"  style={{height:"100%",width:"100%",color:"black"}}>
              <div class="centered" style={{fontSize:"22px", width:"90%"}}><i>Hier</i> finden Sie eine Übersicht über alle eingetragenen Partner aus der Region. Sie können die Auswahl filtern, um schneller die entsprechenden Partner zu finden. Anschließend können Sie direkt Kontakt aufnehmen und gemeinsam an Ihrem Projekt arbeiten.</div>
            </div>
          </Grid>
        </Grid>
      </div>
          {list()}
        </Route>
        {footer()}
        </div>
      } 
    </div>
    </div>
  );

  function getElements(content, tag, expand) {
    var contentElementBuffer = [];
    content.forEach(element => {
      if(tag === "Alle") {
        contentElementBuffer.push(createElement(element,expand));
      } else {
        if(element.get("tags").includes(tag)) {
          contentElementBuffer.push(createElement(element,expand))
        }
      }
      
    })
    return contentElementBuffer;
  }

  //Create Element
  function createElement(element,tag) {
    
    var createTagElements = [];
    element.get("tags").forEach(tagElement => {
      createTagElements.push(<Chip style={{margin:"1px",height: "20px",fontSize: "9pt"}} label={tagElement}/>);
    });

    return <Card className={classes.root} m={1} display="flex" alignItems="center" flexDirection="column">
    <CardHeader style={{textAlign:"left"}}
      avatar={
        <Avatar aria-label="recipe">
          <img alt="C" src={element.get("contact").photo} style={{height:"100%"}} className={classes.avatar}></img>
        </Avatar>
      }
      //action={
      //  <IconButton aria-label="settings">
      //    <MoreVertIcon />
      //  </IconButton>
      //}
      title={element.get("Name").substring(0, 28)}
      subheader={element.get("contact").name}
    />
    <CardMedia
      className={classes.media}
      image={element.get("Logo")}
      title={element.get("Name")}
    />
    <CardContent style={{height:"310px"}}>
      <Typography variant="body2" color="textSecondary" component="p" style={{marginBottom:"15px", textAlign:"left",height:"110px"}}>
      {element.get("short").substring(0, 200)}
      </Typography>
      <Typography variant="body2" color="textSecondary" component="p" style={{fontWeight:"bold"}}>Angebote</Typography>
        <Typography variant="body2" color="textSecondary" component="p">
          <ul style={{textAlign:"left",height:"165px"}}>
            <li key="1">{element.get("point1")}</li>
            <li key="2">{element.get("point2")}</li>
            <li key="3">{element.get("point3")}</li>
            <li key="4">{element.get("point4")}</li>
          </ul>
        </Typography>
    </CardContent>

    <Collapse in={tag} timeout="auto" unmountOnExit style={{marginBottom:"40px"}}>
      <CardContent>
        
      {createTagElements}

      </CardContent>
    </Collapse>


    <CardActions disableSpacing style={{position:"absolute",bottom:"0px",width:"95%"}}>
      <a target="_blank" rel="noopener noreferrer" href={element.get("URL")}>
        <IconButton aria-label="Website beuschen">
          <FavoriteIcon />
        </IconButton>
      </a>

      <a href={"mailto:"+element.get("contact").mail+"?subject=Nachhaltigkeit Südwestfalen"}>
        <IconButton aria-label="share">
          <ShareIcon />
        </IconButton>
      </a>

      <IconButton
        className={clsx(classes.expand, {
          [classes.expandOpen]: tag
        })}
        onClick={handleExpandClick}
        aria-expanded={tag}
        aria-label="show more"
      >
        <ExpandMoreIcon />
      </IconButton>
    </CardActions>
  </Card>
  }
}

export default App;





/*
 //Add Meta Data
  var KoZe = {
    name: "Kompetenzzentrum Siegen",
    logo: "https://www.simplidev.de/wp-content/uploads/2019/05/logo-mittelstand-4-0-kompetenzzentrum-siegen.png",
    url: "https://kompetenzzentrum-siegen.digital",
    contact: {
      name: "Dr. Nico Castelli",
      photo: "https://www.wineme.uni-siegen.de/wp-content/uploads/2017/11/Nico_Castelli.jpg",
      mail: "nico.castelli@fit.fraunhofer.de",
    },
    short: "Das Mittelstand 4.0: Kompetenzzentrum Siegen unterstützt kleine und mittlere Unternehmen dabei Projekte zum Thema Nachhaltigkeit durch digitale Technologien zu konzipieren und prototypisch umzusetzen.",
    point1: "Veranstaltung und Netzwerke zum Thema Nachhaltigkeit",
    point2: "Demonstratoren zu Umsetzungen, Prototypen und Möglichkeiten",
    point3: "Konzeption und Umsetzung konkreter prototypischer Projekte",
    point4: "Qualifizierung und Schulung",
    tags: ["Energieeffizienz","Ressourceneffizienz","Software", "Konzeption", "KMU", "Qualifizierung", "Veranstaltungen", "Netzwerk"]
  }

  var sustain = {
    name: "sustainKMU",
    logo: "https://praxlabs.de/wp-content/uploads/2019/08/SustainKMU-1.jpg",
    url: "https://digital-energy.nrw",
    contact: {
      "name": "Sebastian Taugerbeck",
      "photo": "https://praxlabs.de/wp-content/uploads/2019/08/Sebastian_Taugerbeck-1.jpg",
      "mail": "sebastian.taugerbeck@uni-siegen.de",
    },
    short: "sustainKMU ist ein Forschungsprojekt, welches digitale Tools und Qualifizierungsmaßnahmen entwickelt, um kleine und mittlere Unternehmen bei Thema Energie- und Ressourceneffizienz zu unterstützen.",
    point1: "Veranstaltung und Netzwerke zum Thema Nachhaltigkeit",
    point2: "Demonstratoren zu Umsetzungen, Prototypen und Möglichkeiten",
    point3: "Konzeption und Umsetzung konkreter prototypischer Projekte",
    point4: "Qualifizierung und Schulung",
    tags: ["Energieeffizienz","Ressourceneffizienz","Software", "Konzeption", "KMU", "Qualifizierung", "Veranstaltungen", "Netzwerk"]
  }

  var placeholder = {
    name: "Platzhalter",
    logo: "https://www.unfe.org/wp-content/uploads/2019/04/SM-placeholder-1024x512.png",
    url: "#",
    contact: {
      "name": "Max Mustermann",
      "photo": "https://upload.wikimedia.org/wikipedia/commons/8/89/Portrait_Placeholder.png",
      "mail": "#"
    },
    short: "Dies ist ein Beispieltext. Es können hier aktuell 200 Zeichen eingetragen werden, die Informationen zu dem Unternehme/der Person geben.",
    point1: "Personen können sich an mich wenden",
    point2: "wenn diese eines der folgenden",
    point3: "Themen interessiert oder sie",
    point4: "Fragen dazu haben",
    tags: ["Konzeption", "Förderungsberatung", "Beratung", "Auditierung"]
  }
  */